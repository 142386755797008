import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({

    
    colors:{
        brand: {
            100: 'rgba(255, 255, 255, 0.6)',
            500: '#191A22',
            900: '#F5F8FA'
        },
        yellow: {
            100: '#FEE235',
            900: '#FFC831'
        },
        black: {
            900: '#191A22'
        },
        blue : {
            900: '#1473FB'
        },
        grey : "#D3D3D3",
        background : {
            100: '#0E0C1A'
        }

    },
    fonts : {
        body: 'Gilroy',
        heading: 'Archivo,sans-serif',
        regular: 'Oxanium'

    },
    fontWeights : {
        hairline : 100,
        thin: 200,
        light: 300,
        normal: 400,
        medium: 500,
        semibold: 600,
        bold: 700,
        extrabold: 800,
        black: 900,
    }
})

export default theme;