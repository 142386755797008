import React from 'react'
import {Box , Text , Center } from '@chakra-ui/react'

function Cookie() {
  return (
    <>
    <Box bg="#040B11" fontFamily="heading" width="100%">
        <Center>
            <Box width={["85%","75%","75%","75%"]} pt={10} pb={10}>
                <Text as="h1" fontSize={["2.4rem","2.8rem","3rem","3rem"]} color="brand.900" fontWeight="bold" >
                Cookie Policy
                </Text>

                <Text pt={10} as="h3" fontSize={["1rem","1.2rem","1.3rem","1.3rem"]} color="brand.900" fontWeight="bold">
                What Are Cookies and What do They Do on Cratch?
                </Text><br/>
                
                <Text  as="h3" fontSize={["0.9rem","1.rem","1.15rem","1.15rem"]} color="brand.100" >
                Cookies are extremely small text documents which often include an anonymous unique identifier. When you visit Cratch.io we ask your computer for permission to store this file in a part of your hard drive that is specifically set aside for cookies. The information we gather through cookies may include the time and date of your visits, what pages of the website you viewed and how long you spent on our website.
                </Text> <br />
                <Text  as="h3" fontSize={["1rem","1.2rem","1.3rem","1.3rem"]} color="brand.900" fontWeight="bold">
                How do I consent to the Use of Cookies on Cratch?
                </Text><br/>
                
                <Text  as="h3" fontSize={["0.9rem","1.rem","1.15rem","1.15rem"]} color="brand.100" >
                All you need to do is to continue to use our website! By using our website you are deemed to have consented to the use of cookies as described in this Policy. If on the other hand you don’t consent then please read the section below titled “How do you block Cookies on Cratch?”
                </Text> <br />
                <Text as="h3" fontSize={["1rem","1.2rem","1.3rem","1.3rem"]} color="brand.900" fontWeight="bold">
                How do you block Cookies on Cratch?
                </Text><br/>
                
                <Text  as="h3" fontSize={["0.9rem","1.rem","1.15rem","1.15rem"]} color="brand.100" >
                It’s really simple to block our use of cookies - simply activate the settings within your browser. If you are unsure how to do this you can visit All About Cookies where there is extensive information on how to manage cookies and blocking in general for a large range of browsers. 
                </Text> <br />
                <Text  as="h3" fontSize={["1rem","1.2rem","1.3rem","1.3rem"]} color="brand.900" fontWeight="bold">
                What Happens if I Block Cookies?
                </Text><br/>
                
                <Text  as="h3" fontSize={["0.9rem","1.rem","1.15rem","1.15rem"]} color="brand.100" >
                If you decide to block cookies on Cratch, or withhold consent, some aspects of our site may not work properly for you and you may not be able to access all or part of the services made available to you on our website. If you wish to have full functionality, you will need to let your web browser accept cookies. 
                </Text> <br />
                <Text  as="h3" fontSize={["1rem","1.2rem","1.3rem","1.3rem"]} color="brand.900" fontWeight="bold">
                How Does Cratch Use Cookies?
                </Text><br/>
                <Text  as="h3" fontSize={["0.9rem","1.rem","1.15rem","1.15rem"]} color="brand.100" >
                Cratch utilises cookies for:
                </Text> <br />
                <Text  as="h3" fontSize={["0.9rem","1.rem","1.15rem","1.15rem"]} color="brand.100" fontWeight="bold">
                Analytics
                </Text> <br />
                <Text  as="h3" fontSize={["0.9rem","1.rem","1.15rem","1.15rem"]} color="brand.100" >
                Analytics cookies from companies such as Google and Comcast to allow us to identify and count the number of visitors using our website. They also allow us to see how visitors move around our website whilst they are using it. This allows us to improve the way our website works as intended and that people can find the content they are looking for. We do not generally store any personal information that you provide to us in a cookie.
                </Text> <br />
                
               

                
            </Box>
        
        </Center>
        
    </Box>
    </>
  )
}

export default Cookie