import React from 'react'
import {Box , Center , Text , Image} from '@chakra-ui/react'
import Section from './Section'
import { motion } from 'framer-motion'
import team from '../../assets/team.gif'

function Team() {
  return (
    <>
    <Box bgColor="#20243F" fontFamily="heading">
    <Box height={["50vh","50vh","90vh","90vh"]} bg="linear-gradient(to bottom, #202746, #202746, #202847, #202847, #202847, #202744, #202541, #20243e, #202137, #1f1e31, #1d1c2a, #1b1924)" d="flex" alignItems="center" justifyContent="center" fontFamily="heading">
          
          <Box width="100%" height="100%" pr={[10,10,10,40]} pl={[10,10,10,40]}>
              <Center width="100%" height="100%" > 
                <Box width={["100%","100%","60%","50%"]} pb={10}  >
                    <motion.div
                            initial={{ opacity: 0, x: -100 }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: 100 }}
                            transition={{duration: 0.4, ease: 'easeInOut'}}
                            >
                        <Text as="h1" color="brand.900" fontSize={["2rem","2rem","2.8rem","3.2rem"]} fontWeight="bold">
                            Our Mission
                          </Text>
                          <Text as="h3" color="brand.100" fontSize="1.3rem" pt={4} width={ ["100%", "80%"]} pb={10}>
                          Cratch's mission is to make content creation, live streaming and video sharing fun, interactive and very rewarding.

                          </Text>
                         
                            </motion.div>
                          </Box>
                <Box width={["40%","50%"]} display={['none' , 'none' , 'block' , 'block']}>
                  <Image src={team} width="100%" alt="image" />
                </Box>
  
              </Center>
  
          </Box>
             
            
      </Box>

        <Box bg="#0A0914" height="auto">
                    <Center>
                        <Text fontSize={["1.5rem","2rem","2.35rem","2.35rem"]} fontWeight="bold" lineHeight="2.2rem" color="brand.900" pt={20} >
                        Core Leadership
                        </Text>
                     </Center>
                    <Center>
                        <Box width={["100%","100%","90%","80%"]} gridTemplateColumns="1fr 1fr" d={["grid","grid","flex","flex"]} height="100%" pt={20} pl={[10, 10, 5 ,0]} pr={[5, 10, 5 ,0]}>

                            <Section />
                        </Box>
                     </Center>
                        
        </Box>
    </Box>


    </>
  )
}

export default Team