import React from 'react'
import {Box , Text , Center, Image , Link, Button, useToast} from '@chakra-ui/react'
import video from '../../assets/metatrailer.mp4'
import {motion} from 'framer-motion'
import Third from '../Home/sections/Third/Third'
import Typical from 'react-typical'
import bg from '../../assets/bg.svg'
import logo from '../../assets/metacratch.webp'
import land from '../../assets/land.webp'
import nft from '../../assets/nft.webp'
import tresor from '../../assets/tresor.webp'
import poster from '../../assets/poster.webp'



function Metaverse() {

    const toast = useToast()
    const AnimateImage = {
        hidden : {
          y: 100,
          opacity:0
        },
        visible : custom => ({
          y: 0,
          opacity:1,
          transition : {delay : custom * 0.2, type: 'spring'},
        }),
      }
      

  return (
      <>
            <Box width="100%" bg="#0a0914" height="auto" fontFamily="heading">
                
                <Box
                        as='video'
                        src={video}
                        muted
                        opacity="0.3"
                        loop
                        autoPlay
                        poster={poster}
                        alt='MetaCratch'
                        objectFit='cover'
                        sx={{
                            aspectRatio: '16/9'
                        }}
                    
                />

<Center>
    <Box  position="absolute"  top={["10vh", "10vh","20vh","20vh","20vh"]} color="brand.900" pt={10} pb={10} textAlign="center" fontSize={["2rem","2rem","3rem","3rem"]} fontWeight="bold" >

            <Center><Image src={logo} width="15rem" alt="logo" mt={[0,5,5,5]}/></Center>
            <Box width="100%">
                <Center width="100%">
                    <Box><Typical
                        steps={['Unique Metaverse Experience', 1000]}
                        wrapper="h1"
                        
                    /></Box>
                    
                </Center>
            </Box>
            <Box width="100%">
            <Center width="100%">
                  <Text as="p" color="brand.100" fontSize={["1rem","1.1rem","1.2rem","1.2rem"]} mt={1} width={["90%","90%", "80%","60%","60%"]}>
                  Join the Metaverse community and connect with your favorite streamers and influencers.

                                    </Text>      
            </Center>
            </Box>
            
                                    <Center pt={[5,5,5,5]}>
                <Link href='https://meta.cratch.io/' isExternal>
                    <Button _hover={{
                      bg: '#AB1BEE',
                      transform: 'scale(1.1)'
                    }}
                    _active={{
                      bg: '#AB1BEE',
                      transform: 'scale(1.1)'
                    }}
                    bg="#AB1BEE" width="13.75rem" height="50px" fontWeight="bold" borderRadius="10px" fontSize={["1rem","1.1rem","1.2rem","1.2rem"]} color="brand.900">
                        Visit MetaCratch
                    </Button>
                </Link>
            </Center>
            </Box>
            
            </Center>

            <Box bgImage={`url(${bg})`} letterSpacing={20} color="#692E6B" pt={[20,10,10,10]} pb={10} textAlign="center" fontSize={["4rem","4rem","6rem","6rem"]} fontWeight="bold">
            <Typical
        steps={['WATCH', 3000, 'EARN', 3000,'PLAY', 3000, 'CREATE', 3000]}
        loop={Infinity}
     
      />
            </Box>
                    <Box bgColor="#0A0914"  fontFamily="heading">

                        <Center bg="linear-gradient(to right top, #d16ba5, #ae5692, #8b417f, #692e6b, #491c57, #321b4f, #1a1846, #00133a, #001632, #001624, #001318, #010e0d)" width="100%" height="100%">
                        <Box width={["85%","80%","79%","79%"]} d={["grid","grid","flex","flex"]}  mt={10}  height="100%" pb={10} justifyContent="center" alignContent="center" alignItems="center">
                        <Box width={["100%","100%","50%","50%"]} height="100%" pl={5} d={['block' , "block" , "none" , "none"]} >
                        <motion.div
                          initial="hidden"
                          whileInView="visible"
                          variants={AnimateImage}
                          custom={1}
                          ><Image src={land} /></motion.div>
                            </Box>
                            <Box width={["100%","100%","50%","50%"]} >
                            
                                <Text fontSize={["1.9rem","2.1rem","2.1rem","2.5rem"]} fontWeight="bold" mt={5} lineHeight="2.6rem" color="brand.900">
                                Create your Virtual Studio
                                </Text>
                                <Text as="p" color="brand.100" fontSize={["1rem","1.1rem","1.2rem","1.2rem"]} mt={5} width={["100%","100%","90%","90%"]}>
                                Only in MetaCratch you can create and customize your own virtual studio, communicate
                                with your fans and supporters in the Metaverse.
                                Lands and studios are the gateway to support your community and brand; everyone receive rewards.
                                </Text>
                                <motion.div whileTap={{scale : 0.9}}>
                                <Link href='https://meta.cratch.io/' isExternal>
                                <Button _hover={{
                                bg: '#0088FF'
                                }} _active={{
                                bg: '#0088FF'
                                }} bg="#0088FF" mt="50px" width="11rem" height="50px" fontWeight="bold" borderRadius="10px" fontSize={["1.1rem","1.2rem","1.3rem","1.3rem"]} color="brand.900">
                                    Create Studio
                                </Button>
                                </Link>
                                </motion.div>
                            </Box>
                            <Box width={["100%","100%","50%","50%"]}  height="100%" pl={5} d={['none' , "none" , "block" , "block"]}>
                            <motion.div
                          initial="hidden"
                          whileInView="visible"
                          variants={AnimateImage}
                          custom={1}
                          >
                                <Image src={land} />
                                </motion.div>
                            </Box>
                        </Box>
                    </Center>
        
                </Box>

                <Box bgColor="#0A0914" fontFamily="heading">

                        <Center bg="linear-gradient(to right, #0f0d19, #191529, #261939, #371c48, #4b1c55, #4b1c55, #4b1c55, #4b1c55, #371c48, #26193a, #18142a, #0e0c1a)" width="100%" height="100%">
                        <Box width={["85%","80%","79%","79%"]} d={["grid","grid","flex","flex"]}  mt={10}  height="100%" pb={10} justifyContent="center" alignContent="center" alignItems="center">
                        <Box width={["100%","100%","50%","50%"]} height="100%" pl={5} d={['block' , "block" , "none" , "none"]} >
                        <motion.div
                          initial="hidden"
                          whileInView="visible"
                          variants={AnimateImage}
                          custom={1}
                          >
                                <Image src={tresor} />
                                </motion.div>
                            </Box>
                            <Box width={["100%","100%","50%","50%"]} >
                            
                                <Text fontSize={["1.9rem","2.1rem","2.1rem","2.5rem"]} fontWeight="bold" mt={5} lineHeight="2.6rem" color="brand.900">
                                The $CRTC Token
                                </Text>
                                <Text as="p" color="brand.100" fontSize={["1rem","1.1rem","1.2rem","1.2rem"]} mt={5} width={["100%","100%","95%","95%"]}>
                                $CRTC is our main utility token that allows you to buy and sell LANDS and ASSETS in MetaCratch. 
                                </Text>
                                <motion.div whileTap={{scale : 0.9}}>
                                <Button onClick={() => {
                        toast({
                          title: `Coming soon! Stay tuned!`,
                          position: "top",
                          isClosable: true,
                          status: 'info', 
                        })
                      }} _hover={{
                                bg: '#0088FF'
                                }} _active={{
                                bg: '#0088FF'
                                }} bg="#0088FF" mt="50px" width="10rem" height="50px" fontWeight="bold" borderRadius="10px" fontSize={["1.1rem","1.2rem","1.3rem","1.3rem"]} color="brand.900">
                                    Buy
                                </Button>
                                </motion.div>
                            </Box>
                            <Box width={["100%","100%","50%","50%"]}  height="100%" pl={5} d={['none' , "none" , "block" , "block"]}>
                            <motion.div
                          initial="hidden"
                          whileInView="visible"
                          variants={AnimateImage}
                          custom={1}
                          >
                                <Image src={tresor} />
                                </motion.div>
                            </Box>
                        </Box>
                    </Center>
        
                </Box>
                <Box bgColor="#0A0914" fontFamily="heading">

                        <Center bg="linear-gradient(to left, #00133a, #0e173b, #191c3c, #21213e, #28263f, #28263f, #28263f, #28263f, #21213e, #191c3c, #0e173b, #00133a);" width="100%" height="100%">
                        <Box width={["85%","80%","79%","79%"]} d={["grid","grid","flex","flex"]}  justifyContent="center" alignContent="center" alignItems="center">
                        <Box width={["100%","100%","50%","50%"]} height="100%" pl={5} d={['block' , "block" , "none" , "none"]} >
                        <motion.div
                          initial="hidden"
                          whileInView="visible"
                          variants={AnimateImage}
                          custom={1}
                          > <Image src={nft} /></motion.div>
                            </Box>
                            <Box width={["100%","100%","50%","50%"]} pb={10}>
                            
                                <Text fontSize={["1.9rem","2.1rem","2.1rem","2.5rem"]} fontWeight="bold" mt={5} lineHeight="2.6rem" color="brand.900">
                                Community NFTs 
                                </Text>
                                <Text as="p" color="brand.100" fontSize={["1rem","1.1rem","1.2rem","1.2rem"]} mt={5} width={["100%","100%","90%","90%"]}>
                                Non-Fungible Tokens (NFTs) are virtual tokens minted on the blockchain for digital scarcity, security, and authenticity. Allow your community to own a piece of your digital content, digital assets and brands. 
                                </Text>
                                <motion.div whileTap={{scale : 0.9}}>
                                <Link href='https://meta.cratch.io/' isExternal>
                                <Button _hover={{
                                bg: '#0088FF'
                                }} _active={{
                                bg: '#0088FF'
                                }} bg="#0088FF" mt="50px" width="12rem" height="50px" fontWeight="bold" borderRadius="10px" fontSize={["1.1rem","1.2rem","1.3rem","1.3rem"]} color="brand.900">
                                    Discover Assets
                                </Button>
                                </Link>
                                </motion.div>
                            </Box>
                            <Box width={["100%","100%","50%","50%"]}  height="100%" pl={5} d={['none' , "none" , "block" , "block"]}>
                            <motion.div
                          initial="hidden"
                          whileInView="visible"
                          variants={AnimateImage}
                          custom={1}
                          ><Image src={nft} /></motion.div>
                            </Box>
                        </Box>
                    </Center>
        
                </Box>
                

                <Box pb={5} bg="radial-gradient(circle at 70% -10%, #131024, #040C14)">
                    <Third 
                      heading="Want to know what others are saying about Cratch?" 
                      bg="radial-gradient(circle at 70% -10%, #131024, #040C14)" 
                      cardColor="rgba(255,255,255,.01)"
                      paragraph="Join one of the fastest-growing community of Metaverse lovers, crypto enthusiasts, live streamers and content creators. We are intentional about building an international community that is currently supported in 5 languages with more to come."
                      
                      />
                    </Box>

                    
            </Box> 
  </>)
}

export default Metaverse