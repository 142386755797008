import React from 'react'
import {Box , Text , Center , UnorderedList , ListItem } from '@chakra-ui/react'

function TermsAndConditions() {
  return (
    <>
    <Box bg="#040B11" fontFamily="heading" width="100%">
        <Center>
            <Box width={["85%","75%","75%","75%"]} pt={10} pb={10}>
                <Text as="h1" fontSize={["2.4rem","2.8rem","3rem","3rem"]} color="brand.900" fontWeight="bold" >
                Terms of Use
                </Text>

                <Text pt={10} as="h3" fontSize={["1rem","1.1rem","1.2rem","1.2rem"]} color="brand.900" fontWeight="bold" opacity="0.8">
                PLEASE READ THESE TERMS OF USE ("TERMS") CAREFULLY. BY CLICKING TO ACCEPT, OR BY ACCESSING OR USING OUR SERVICES (AS DEFINED HEREIN), YOU AGREE TO BE BOUND BY THESE TERMS OF USE IN THEIR ENTIRETY
                </Text><br/>
                <Text as="h3" fontSize="1.3rem" color="brand.900" fontWeight="bold">
                1. General Service Terms
                </Text><br/>
                
                <Text  as="h3" fontSize={["0.9rem","1.rem","1.15rem","1.15rem"]} color="brand.100" >
                1.1. Support for CRTC Only: The only cryptocurrency supported by Cratch is CRTC. If you want to store, transfer and/or use any cryptocurrency other than CRTC, you cannot do it through the Cratch Services. If you send or transfer to your Cratch Account any cryptocurrency that is not CRTC, Cratch shall not be responsible for receiving or storing it in any way and you may lose any right in connection to such cryptocurrency or value without any right or claim to Cratch.
                </Text> <br />
                
                <Text  as="h3" fontSize={["0.9rem","1.rem","1.15rem","1.15rem"]} color="brand.100" >
                1.2. Continuity of Services: Our Services may evolve over time. This means we may make changes, replace, or discontinue (temporarily or permanently) our Services at any time for any reason with or without notice. In this case, you may be prevented from accessing or using our Services. If, in our sole discretion, we decide to permanently discontinue our Services, we will provide you with notice via our website.
                </Text>
                <br/>
                <Text  as="h3" fontSize={["0.9rem","1.rem","1.15rem","1.15rem"]} color="brand.100" >
                1.3. CRTC Transactions: Cratch cannot and does not guarantee the value of CRTC. You acknowledge and agree that the value of CRTC is highly volatile and that buying, selling, and holding CRTC involves a high degree of risk. Once a transaction request has been submitted to the CRTC network via the Services, the CRTC network will automatically complete or reject the request and you will not be able to cancel or otherwise modify your transaction request. You acknowledge and agree that Cratch is not responsible for any errors or omissions that you make in connection with any CRTC transaction initiated via the Services.
                </Text>
                <br/>
                <Text  as="h3" fontSize={["0.9rem","1.rem","1.15rem","1.15rem"]} color="brand.100" >
                1.4. Unacceptable Use or Conduct: You will not:
                </Text>
                <br/>
                <UnorderedList fontSize={["0.9rem","1.rem","1.15rem","1.15rem"]} color="brand.100" spacing={3}>
                    <ListItem>use the Services in any manner that could interfere with, disrupt, negatively affect, or inhibit other users from fully enjoying the Services, or that could damage, disable, overburden, or impair the functioning of the Services in any manner;</ListItem>
                    <ListItem>use the Services to pay for, support, or otherwise engage in any illegal gambling activities, fraud, money-laundering, or terrorist activities, or other illegal activities;</ListItem>
                    <ListItem>use any robot, spider, crawler, scraper, or other automated means or interface not provided by Cratch to access the Services or to extract data;</ListItem>
                    <ListItem>use or attempt to use another user′s Cratch Account without authorization;</ListItem>
                    <ListItem>attempt to circumvent any content filtering techniques Cratch employs, or attempt to access any service or area of the Services that you are not authorized to access;</ListItem>
                    <ListItem>introduce to the Services any malware, virus, trojan worms, logic bombs, or other harmful material;</ListItem>
                    <ListItem>develop any third-party applications that interact with our Services without our prior written consent, or unless otherwise agreed;</ListItem>
                    <ListItem>provide false, inaccurate, or misleading information;</ListItem>
                    <ListItem>post content or communications that are, in our sole discretion, libellous, defamatory, profane, obscene, pornographic, sexually explicit, indecent, lewd, vulgar, suggestive, harassing, hateful, threatening, offensive, discriminatory, bigoted, abusive, inflammatory, fraudulent, deceptive or otherwise objectionable;</ListItem>
                    <ListItem>post content containing unsolicited promotions or commercial messages (SPAM) or any chain messages or user content designed to deceive or trick the user of the Service;</ListItem>
                    <ListItem>post content containing private information of any third-party including, but not limited to, addresses, phone numbers, email addresses, Social Security numbers and credit card numbers;</ListItem>
                </UnorderedList><br/>
                <Text  as="h3" fontSize={["0.9rem","1.rem","1.15rem","1.15rem"]} color="brand.100" >
                As a user you must not:
                </Text> <br />
                <UnorderedList fontSize={["0.9rem","1.rem","1.15rem","1.15rem"]} color="brand.100" spacing={3}>
                    <ListItem>Modify copies of any materials from the Website.</ListItem>
                    <ListItem>Delete or alter any copyright, trademark or other proprietary rights notices from copies of materials from the Website.</ListItem>
                    <ListItem>Access or use for any commercial purposes any part of the Website or any services or materials available through the Website.</ListItem>
                    
                </UnorderedList><br/>
                <Text as="h3" fontSize="1.3rem" color="brand.900" fontWeight="bold">
                2. Intellectual Property
                </Text><br/>
                <Text  as="h3" fontSize={["0.9rem","1.rem","1.15rem","1.15rem"]} color="brand.100" >
                2.1. Ownership of Intellectual Property Rights: We and our licensors retain all right, title, and interest (including all copyright, trademark, patent, trade secrets, and all other intellectual property rights) in our Services and all content on our Services, including our trademarks, service marks, designs, logos, URLs, and trade names that are displayed on our Service, which we refer to in these Terms, collectively, as the “Cratch Materials”. We hereby grant you a limited, nonexclusive, and non-sublicensable license to access and use the Cratch Materials for your personal or internal business use. Such license is subject to these Terms and does not permit any resale of the Cratch Materials; the distribution, public performance or public display of any Cratch Materials; modifying or otherwise making any derivative uses of the Cratch Materials, or any portion thereof; or any use of the Cratch Materials other than for their intended purposes. The license granted under this Section 12 will automatically terminate if we suspend or terminate your access to the Services. We will own exclusive rights, including all intellectual property rights, to any feedback, suggestions, ideas or other information or materials regarding Cratch or our Services that you provide, whether by email, posting through our Services or otherwise, which we refer to in these Terms as Feedback. Any Feedback you submit is non-confidential and will become the sole property of Cratch. We will be entitled to the unrestricted use and dissemination of such Feedback for any purpose, commercial or otherwise, without acknowledgment or compensation to you. You may not use, copy or retransmit anything on our website without our permission. We reserve all rights not expressly granted herein.
                </Text> <br />
                <Text  as="h3" fontSize={["0.9rem","1.rem","1.15rem","1.15rem"]} color="brand.100" >
                2.2. Trademarks: Cratch and the Cratch logo are Cratch′s or our licensor′s trademarks, registered trademarks or service marks. Any other trademarks mentioned in our website or apps are the property of their respective owners.
                </Text>
                <br/>
                <Text  as="h3" fontSize={["0.9rem","1.rem","1.15rem","1.15rem"]} color="brand.100" >
                2.3. Feedback: You may voluntarily post, submit or otherwise communicate to us any questions, comments, suggestions, ideas, original or creative materials or other information about Cratch or our Services (collectively, “Feedback”). You understand that we may use such Feedback for any purpose, commercial or otherwise, without acknowledgment or compensation to you, including, without limitation, to develop, copy, publish, or improve the Feedback in Cratch′s sole discretion. You understand that Cratch may treat Feedback as nonconfidential.
                </Text>
                <br/>
                <Text as="h3" fontSize="1.3rem" color="brand.900" fontWeight="bold">
                3. Third-Party Content
                </Text><br/>
                <Text  as="h3" fontSize={["0.9rem","1.rem","1.15rem","1.15rem"]} color="brand.100" >
                We may provide information about third-party products, services, activities or events, or we may allow third parties to make their content and information available on or through the Services (collectively, “Third-Party Content”). We provide Third-Party Content as a service to those interested in such content. Your dealings or correspondence with third parties and your use of or interaction with any Third-Party Content are solely between you and the third party. Cratch does not control or endorse, and makes no representations or warranties regarding, any Third-Party Content, and your access to and use of such Third-Party Content is at your own risk.
                </Text> <br />
                <Text as="h3" fontSize="1.3rem" color="brand.900" fontWeight="bold">
                4. Disclaimer of Warranties
                </Text><br/>
                <Text  as="h3" fontSize={["0.9rem","1.rem","1.15rem","1.15rem"]} color="brand.100" >
                OUR SERVICES ARE PROVIDED “AS IS” WITH NO WARRANTY OF ANY KIND. Your use of our Services is at your sole risk. We and our licensors, service providers or subcontractors (if any) make no representations or warranties about the suitability of the information, software, products and services contained in our Services for any purpose or their compliance with any accounting rules, principles or laws, and expressly disclaim any representation or warranty that the Services will be free from errors, viruses or other harmful components, that communications to or from the Services will be secure and not intercepted, that the Services and other capabilities offered from the Services will be uninterrupted, or that their content will be accurate, complete or timely. EXCEPT AS EXPRESSLY STATED IN THESE TERMS, WE DISCLAIM ALL WARRANTIES AND CONDITIONS, EXPRESS, IMPLIED, OR STATUTORY, INCLUDING WITHOUT LIMITATION THE IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY, AND FITNESS FOR A PARTICULAR PURPOSE. YOU ACKNOWLEDGE THAT YOU HAVE NOT ENTERED INTO THIS AGREEMENT IN RELIANCE UPON ANY WARRANTY OR REPRESENTATION EXCEPT THOSE SPECIFICALLY SET FORTH IN THESE TERMS. Some jurisdictions do not allow the disclaimer of implied terms in contracts with consumers, so some or all of the disclaimers in this Section 14 may not apply to you.
                </Text> <br />
                <Text as="h3" fontSize="1.3rem" color="brand.900" fontWeight="bold">
                5. No Advice
                </Text><br/>
                <Text  as="h3" fontSize={["0.9rem","1.rem","1.15rem","1.15rem"]} color="brand.100" >
                Cratch is not acting and cannot act as an advisor, including as to any financial, legal, investment, insurance and/or tax matters. Any information provided by Cratch is for general information only. You are solely responsible for determining whether any contemplated transaction is appropriate for you.
                </Text> <br />

                
            </Box>
        
        </Center>
        
    </Box>
    </>
  )
}

export default TermsAndConditions