import React from 'react'
import {Box, Button, Center, Image, Input, Text} from "@chakra-ui/react"
import {AiOutlineSwap} from "react-icons/ai"
import usdt from "../../assets/usdt.png"
import token from "../../assets/logo-swap.png"

const Buy = () => {

    const [usdtPrice,setUsdtPrice] = React.useState("1")
    const [crtcPrice,setCrtcPrice] = React.useState("6.66")


    const checkPrice = (e) => {
        if(e.target.value === "" || e.target.value === " " || e.target.value === "0" || isNaN(e.target.value) || usdtPrice.length === 0){
            setUsdtPrice("1") 
        }
        
        else {
            setUsdtPrice(e.target.value)
            setCrtcPrice((Number(e.target.value)/0.15).toFixed(2))
        }

                
    }

    return(
        <>
            <Box bg="#0A0914" >
        <Center height="69vh" pt={14} pb={5} width="100%" 
        bg="linear-gradient(to top, #030a12, #060f18, #08131e, #091724, #091a2a, #091a2b, #081b2d, #081b2e, #08192b, #081727, #071424, #061221);" 
        fontFamily="heading">
        
            <Box height="100%" width={["90%","80%","60%","35%"]}  bg="#0E0C1A" boxShadow="8px 8px 20px 0px rgba(0,0,0,0.75)" borderRadius="10px" >
               <Box d="flex" justifyContent="flex-end" w="90%" pt={10}>
               <Button _active={{bg : "rgb(171, 27, 238,0.8)"}} _hover={{bg : "rgb(171, 27, 238,0.8)"}} bg="#AB1BEE" borderRadius="10px" pl={8} pr={8} pt={4} pb={4} float="right">Connect Wallet</Button>
                </Box>
                <Center width="100%" height="80%" >
                    <Box width={["90%","90%","80%","80%"]} height="100%" d="relative" pt={5}> 

                <Text as="h1" fontSize="1.4rem" fontFamily="Arial,Roboto" pb={5}>Swap Tokens</Text>
                    
                    <Center w="100%" h="22%" bg="#091A2A" borderRadius="10px" position="relative">
                        <Box w="90%" h="80%">
                                <Center d="flex" h="100%">
                                    <Box w="50%" d="flex" justifyContent="flex-start">
                                        <Box>
                                            <Image src={usdt} alt="usdt" w="2.7rem" h="2.7rem" />
                                        </Box>
                                        <Box pl={5} textAlign="left">
                                            <Text as="h3" fontWeight="bold" fontSize="1rem">USDT</Text>

                                            <Text as="h3" fontSize="0.8rem" color="#737476">~$1.00</Text>
                                        </Box>
                                    </Box>

                                    <Box w="50%" d="flex" justifyContent="flex-end">
                                        <Box pl={5} textAlign="right">
                                                <Input textAlign="right" border="none" value={usdtPrice} onChange={checkPrice} fontSize="1.1rem" />

                                                <Text as="h3" fontSize="0.8rem" color="#737476">${Number(usdtPrice).toFixed(2)}</Text>
                                            </Box>
                                    </Box>
                                </Center>
                        </Box>
                    </Center>

                        <Center w="100%" >
                            <Center bg="#801fef" mt={6} position="absolute" zIndex="100" w="3.3rem" h="3rem" borderRadius="15px" transform="rotate(90deg)">
                                <AiOutlineSwap size="1.5rem" />
                            </Center>
                        </Center>

                        <Center w="100%" h="22%" bg="#091A2A" borderRadius="10px" mt={6} position="relative">
                        <Box w="90%" h="80%">
                                <Center d="flex" h="100%">
                                    <Box w="50%" d="flex" justifyContent="flex-start">
                                        <Box>
                                            <Image src={token} alt="usdt" w="2.7rem" h="2.7rem" />
                                        </Box>
                                        <Box pl={5} textAlign="left">
                                            <Text as="h3" fontWeight="bold" fontSize="1rem">CRTC</Text>

                                            <Text as="h3" fontSize="0.8rem" color="#737476">~$0.15</Text>
                                        </Box>
                                    </Box>

                                    <Box w="50%" d="flex" justifyContent="flex-end">
                                        <Box pl={5} textAlign="right">
                                                <Text as="h3" fontSize="1.1rem">{crtcPrice}</Text>

                                                <Text as="h3" fontSize="0.8rem" color="#737476">{Number(crtcPrice).toFixed(2)}</Text>
                                            </Box>
                                    </Box>
                                </Center>
                        </Box>
                    </Center>


                    
                        <Button _active={{
                    bg: 'rgb(128, 31, 239,0.8)'
                  }} _hover={{
                    bg: 'rgb(128, 31, 239,0.8)'
                  }} bg="#801fef" w="100%" mt={7} h="3.2rem" borderRadius="15px">
                                Execute Swap
                        </Button>
                    

                    </Box>
                </Center>

            </Box>
           
        </Center>

        </Box>
        
        </>
        )
}


export default Buy