import React from 'react'
import {Box, Center,Image , List, Text,ListItem} from '@chakra-ui/react';
import logo from '../../../assets/logo.webp'
import { Link as ReachLink } from "@reach/router"
import { Link } from '@chakra-ui/react'
import Whitepaper from '../../../assets/Whitepaper.pdf';


function Footer() {
  
  return (
    <>
      <Box height="75%" bg="#0a0914" fontFamily="Archivo,sans-serif">
        

        <Box ml={[10,20,20,40,40]} pt={20} d={["grid","grid","flex","flex"]}>
          <Box>
            <Image src={logo} alt="logo" width="8rem"  />

            <Text as="h1" fontSize={["1rem","1.2rem","1.3rem","1.3rem"]} mt={[10,20,20,20]} color="brand.900" fontWeight="bold">
                    Explore
            </Text>
                <Box d="flex">
                      <List mt={5} spacing={4} color="brand.100" fontSize={["1rem","1.1rem","1.2rem","1.2rem"]} cursor="pointer">

                      <ListItem><Link as={ReachLink} to="/platform">Platform</Link></ListItem>
                      <ListItem><Link as={ReachLink} to="/metaverse">Metaverse</Link></ListItem>

                          <ListItem><Link as={ReachLink} to="/token">Token</Link></ListItem>
                      
                      
                          
                      
                          
                      </List>

                      <List ml={20} mt={5} spacing={4} color="brand.100" fontSize={["1rem","1.1rem","1.2rem","1.2rem"]} cursor="pointer">

                      <ListItem><Link as={ReachLink} to="/roadmap">Roadmap</Link></ListItem>
                      <ListItem><Link as={ReachLink} to="/community">Community</Link></ListItem>
                        <ListItem><Link as={ReachLink} to="/team">About Us</Link></ListItem>
                        
                        
                        
                          
                      </List>
                </Box>
            </Box>
<Box d="flex">
            <Box mt={10} ml={[0,0 ,24,24]}>
            

                <Text as="h1" fontSize={["1rem","1.2rem","1.3rem","1.3rem"]} mt={[5,5,20,20]} color="brand.900" fontWeight="bold">
                        Legal
                </Text>
                <Box d="flex">
                      <List mt={5} spacing={4} color="brand.100" fontSize={["1rem","1.1rem","1.2rem","1.2rem"]} cursor="pointer">
                          <ListItem><Link as={ReachLink} to="/terms">Site Terms</Link></ListItem>
                          <ListItem><Link as={ReachLink} to="/terms-and-conditions">Terms & Conditions</Link></ListItem>
                          <ListItem><Link href="mailto:contact@cratch.io">Support</Link></ListItem>
                          
                      </List>

                </Box>
            </Box>

            <Box mt={10} ml={[10,10,10,24]}>
            

                  <Text as="h1" fontSize={["1rem","1.2rem","1.3rem","1.3rem"]} mt={[5,5,20,20]} color="brand.900" fontWeight="bold">
                          Documents
                  </Text>
                  <Box d="flex">
                        <List mt={5} spacing={4} color="brand.100" fontSize={["1rem","1.1rem","1.2rem","1.2rem"]} cursor="pointer">
                            <ListItem>
                              <a  href={Whitepaper} target="_blank" rel="noreferrer">Whitepaper</a>
                            </ListItem>
                            <ListItem><Link as={ReachLink} to="/cookie">Cookie Policy</Link></ListItem>
                            
                        </List>

                  </Box>
              </Box>
          </Box>
        </Box>
        <Center>
        <Text as="h2" color="brand.100" mt="70px" pb="15px" fontSize="0.8rem">
        Copyright © Cratch 2025. All rights reserved.
        </Text>

        </Center>
        
      </Box>
    
    
    </>

  )
}

export default Footer