import React,{useState} from 'react'
import { Box , Image , Button , Divider, Icon } from '@chakra-ui/react'
import logo from '../../../assets/logo.webp'
import {Link as ReachLink } from "@reach/router"
import { Link } from '@chakra-ui/react'
import {IoMenu} from 'react-icons/io5'
import {motion} from 'framer-motion'




function Navbar() {


  const [ids, setIds] = useState(0)
  const [isActive , setIsActive] = useState(false);


  const LinkStyles = {
                              textDecoration: 'underline',
                              textUnderlineOffset : '15px',
                              textDecorationColor: 'yellow.900',
                              textDecorationThickness : '5px',
                              color: 'brand.900'
  }
  return (<>
  

    <header >
      
        <Box d="flex" pos="relative" zIndex={1000} alignItems="center" justifyContent="space-between" height="12vh"  mg={[5,5,10,10]} bg='#0E0C1A' fontFamily="heading">
          <Box d="flex" alignItems="center" justifyContent="space-between">
              <Link as={ReachLink} to="/">
                    <Image height={[10,50]} src={logo} alt="logo" ml={[10,10,20,20]} width={['70%' , "auto"]}/>
              </Link>
            
            <Divider orientation='vertical' color="grey" height={10} ml={10} opacity="0.1"/>

            <Link as={ReachLink} to="/platform" onClick={() => setIds(6)} display={['none' , 'none' , 'block' , 'block']}>
                                  
                            <Button sx={ids===6 ? LinkStyles : ""} color="brand.100" fontSize="1.1rem" variant="link" ml={[0,0,5,5,10]} fontWeight="normal" _hover={{
                                textDecoration: 'underline',
                                textUnderlineOffset : '15px',
                                textDecorationColor: 'yellow.900',
                                textDecorationThickness : '5px',
                                color: 'brand.900'
                              }}>
                                Platform
                            </Button>
                      </Link>
                    <Link as={ReachLink} to="/metaverse" onClick={() => setIds(2)} display={['none' , 'none' , 'block' , 'block']}>
                                  
                            <Button sx={ids===2 ? LinkStyles : ""} color="brand.100" fontSize="1.1rem" variant="link" ml={[0,0,5,5,10]} fontWeight="normal" _hover={{
                                textDecoration: 'underline',
                                textUnderlineOffset : '15px',
                                textDecorationColor: 'yellow.900',
                                textDecorationThickness : '5px',
                                color: 'brand.900'
                              }}>
                                Metaverse
                            </Button>
                      </Link>
                      <Link as={ReachLink} to="/token" onClick={() => setIds(1)} display={['none' , 'none' , 'block' , 'block']}>
                            <Button sx={ids===1 ? LinkStyles : ""} color="brand.100" fontSize="1.1rem" variant="link" ml={[0,0,5,5,10]} fontWeight="normal" _hover={{
                              textDecoration: 'underline',
                              textUnderlineOffset : '15px',
                              textDecorationColor: 'yellow.900',
                              textDecorationThickness : '5px',
                              color: 'brand.900'
                            }}>
                              
                              Token
                          </Button>
                      </Link>

                      

                    
                            <Link as={ReachLink} to="/team" onClick={() => setIds(3)} display={['none' , 'none' , 'none' , 'block']}>
                                <Button sx={ids===3 ? LinkStyles : ""} color="brand.100" fontSize="1.1rem" variant="link" ml={[0,0,5,5,10]} fontWeight="normal" _hover={{
                                    textDecoration: 'underline',
                                    textUnderlineOffset : '15px',
                                    textDecorationColor: 'yellow.900',
                                    textDecorationThickness : '5px',
                                    color: 'brand.900'
                                  }}>
                                    Team
                                </Button>
                            </Link>

                            <Link as={ReachLink} to="/community" onClick={() => setIds(4)} display={['none' , 'none' , 'none' , 'block']}>
                                <Button sx={ids===4 ? LinkStyles : ""} color="brand.100" fontSize="1.1rem" variant="link" ml={[0,0,5,5,10]} fontWeight="normal" _hover={{
                                    textDecoration: 'underline',
                                    textUnderlineOffset : '15px',
                                    textDecorationColor: 'yellow.900',
                                    textDecorationThickness : '5px',
                                    color: 'brand.900'
                                  }}>
                                    Community
                                </Button>
                            </Link>

                            <Link as={ReachLink} to="/roadmap" onClick={() => setIds(5)} display={['none' , 'none' , 'none' , 'block']}>
                            <Button sx={ids===5 ? LinkStyles : ""} color="brand.100" fontSize="1.1rem" variant="link" ml={[0,0,0,5,10]} fontWeight="normal" _hover={{
                                textDecoration: 'underline',
                                textUnderlineOffset : '15px',
                                textDecorationColor: 'yellow.900',
                                textDecorationThickness : '5px',
                                color: 'brand.900'
                              }}>
                                Roadmap
                            </Button>
                        </Link>
                            
          </Box>
        <Box d="flex" alignItems="center" justifyContent="space-between" >
                    
                      
                  <Link href='https://account.cratch.io/' isExternal display={['none' , 'none' , 'block' , "block"]}>
                            <Button width ="120px" color="brand.500" fontSize="1.1rem" bg="yellow.100" mr={[5,20]} fontWeight="bold" _hover={{
                                color: 'brand.500',
                                bg: 'linear-gradient(to right top, #ced16b, #d1d25f, #d5d352, #d9d343, #ddd432, #dfd828, #e2db1a, #e4df01, #e4e700, #e4ef00, #e3f700, #e2ff00);',
                                textDecoration: "none",

                              }}
                              
                              >
                                Connect
                            </Button>
                        </Link>
                    <motion.div whileTap={{scale: 0.5}}
                            initial={{opacity: 0,scale: 0.2}}
                            animate={{opacity: 1 , scale: 1}}
                            transition={{type: 'spring'}}>
                        <Icon onClick={() => setIsActive(!isActive)} color="#FFC831" cursor="pointer" mr={10} fontSize="2rem" display={['block' , 'block' , 'none' , 'none']}>
                          
                            
                          
                          <IoMenu  />
                        </Icon></motion.div>
                        {isActive && (
                          <motion.div initial={{opacity: 0, scale: 0.5}}
                          animate={{opacity: 1, scale: 1.1}}
                          exit={{opacity: 0, scale: 0.5}}
                          transition={{delay:"0.1s" , type: "spring"}}
                          >

                          
                            <Box borderRadius="10px" width={["40vw","35vw","30vw","25vw"]} gap={5} bg="#0E0C1A" pos="fixed" top={10} right={5} d="flex" flexDirection="column" alignItems="center" justifyContent="evenly">
                          <Link mt={5} as={ReachLink} to="/platform" onClick={() => {setIds(6);setIsActive(false);}} >
                                  
                                  <Button  color="brand.100" fontSize={["0.9rem","1.1rem","1.1rem","1.1rem"]} variant="link"  fontWeight="normal" _hover={{

                                      color: 'brand.900'
                                    }}>
                                      Platform
                                  </Button>
                            </Link>
                          <Link as={ReachLink} to="/metaverse" onClick={() =>{ setIds(2);setIsActive(false);}} >
                                        
                                  <Button  color="brand.100" fontSize={["0.8rem","1.1rem","1.1rem","1.1rem"]} variant="link"  fontWeight="normal" _hover={{

                                      color: 'brand.900'
                                    }}>
                                      Metaverse
                                  </Button>
                            </Link>
                            <Link as={ReachLink} to="/token" onClick={() => {setIds(1);setIsActive(false);}} >
                                  <Button  color="brand.100" fontSize={["0.9rem","1.1rem","1.1rem","1.1rem"]} variant="link"  fontWeight="normal" _hover={{

                                    color: 'brand.900'
                                  }}>
                                    
                                    Token
                                </Button>
                            </Link>
      
                            
      
                          
                                  <Link as={ReachLink} to="/team" onClick={() => {setIds(3);setIsActive(false);}} >
                                      <Button  color="brand.100" fontSize={["0.9rem","1.1rem","1.1rem","1.1rem"]} variant="link" fontWeight="normal" _hover={{
                                          color: 'brand.900'
                                        }}>
                                          Team
                                      </Button>
                                  </Link>
      
                                  <Link as={ReachLink} to="/community" onClick={() => {setIds(4);setIsActive(false);}} >
                                      <Button color="brand.100" fontSize={["0.9rem","1.1rem","1.1rem","1.1rem"]} variant="link"  fontWeight="normal" _hover={{
                                          color: 'brand.900'
                                        }}>
                                          Community
                                      </Button>
                                  </Link>
      
                                  <Link as={ReachLink} to="/roadmap" onClick={() => {setIds(5);setIsActive(false);}} >
                                  <Button  color="brand.100" fontSize={["0.9rem","1.1rem","1.1rem","1.1rem"]} variant="link"  fontWeight="normal" _hover={{

                                      color: 'brand.900'
                                    }}>
                                      Roadmap
                                  </Button>
                              </Link>
                                  
                              <Link href='https://account.cratch.io/' isExternal onClick={() => setIsActive(false)}>
                            <Button width ="120px" color="brand.500" fontSize={["0.9rem","1.1rem","1.1rem","1.1rem"]} bg="rgb(254, 226, 53,.8)" fontWeight="bold" _hover={{
                                color: 'brand.500',
                                bg: 'linear-gradient(to right top, #ced16b, #d1d25f, #d5d352, #d9d343, #ddd432, #dfd828, #e2db1a, #e4df01, #e4e700, #e4ef00, #e3f700, #e2ff00);',
                                textDecoration: "none",

                              }}
                              
                              >
                                Connect
                            </Button>
                        </Link>

                          </Box>
                          </motion.div>
                        )}
        </Box>
      </Box>
    </header>
  
  
  </>
    
  )
}

export default Navbar