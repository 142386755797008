import React from 'react'
import {Box, Text, Center , FormControl , Input} from '@chakra-ui/react'
import Third from '../Home/sections/Third/Third';
import Fourth from '../Home/sections/Fourth/Fourth';
import {BsArrowRight} from 'react-icons/bs'
import './style.css'
import {motion} from 'framer-motion'
import media from '../../assets/media.webp'
import {db} from './firebase'


function Community() {
    const [email , setEmail] = React.useState("");


    const changeEmail = (e) => {
        setEmail(e.target.value)
      }

     
      const submit = async(e) => {
        e.preventDefault()
        try {
            if(/.+@.+\.[A-Za-z]+$/.test(email)) {
               db.collection("newsletter").add({
                email : email,
                })

          var text = document.getElementById("success");
          text.textContent = "Thank You for Subscribing!";
          setEmail("")
          
        }
      else {
        setEmail(email)
      }
          
        }catch {
            setEmail("")
        }


      }


      const enter = (e) => {
          if(e.key == "Enter"){
            try {
                if(/.+@.+\.[A-Za-z]+$/.test(email)) {
                db.collection("newsletter").add({
                email : email,
                })
              var text = document.getElementById("success");
              text.textContent = "Thank You for Subscribing!"
              setEmail("")
              
            }
            else {
              setEmail(email)
            }
           
        }
                
              catch {
                setEmail("")
              }
             
      
            }
            
          }
      
  return (
    <>
    
    <Box bg="#0A0914" >
        <Center height={["50vh","60vh","88vh","88vh"]} width="100%" 
        bg="linear-gradient(to top, #030a12, #060f18, #08131e, #091724, #091a2a, #091a2b, #081b2d, #081b2e, #08192b, #081727, #071424, #061221);" 
        fontFamily="heading">
        
            <Box height="100%" width="80%" d="flex" pt="5%">
               
                <Box width={["100%","100%","60%","50%"]} height="80%" >
                    <Center width="100%" height="100%" d="relative" pl={[10,10,0,0]}pt={10}> 
                        <motion.div
                    initial={{ opacity: 0, x: -100 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: 100 }}
                    transition={{duration: 0.4, ease: 'easeInOut'}}
                    >
                        <Box width="100%">
                        <Text p={3}  as="h1" fontSize={["2rem","2rem","3.9rem","4.5rem"]}  color="white" fontWeight="bold" lineHeight={["3rem","3rem","5.2rem","5.2rem"]} mb={2}  >
                        <span style={{background: '#FFC831',padding: '10px'}}>Welcome!</span> 
                        </Text>
                        </Box>
                     
                        <Text as="h1" fontSize={["2rem","2rem","3.9rem","4.5rem"]} pl={[2,5,0,0]}  color="white" fontWeight="bold" lineHeight={["3rem","3rem","5.2rem","5.2rem"]}>
                        
                        Join the Community
                        Today.
                        </Text></motion.div>
                    </Center>
                </Box>
                 
                <Box width={["40%","50%"]} display={['none' , 'none' , 'block' , 'block']} bgImage={`url('${media}')`} 
                  bgPosition="right"
                  bgRepeat="no-repeat"
                  bgSize='cover'
                  height="80%"
                  borderRadius="30px"
                >
                </Box>
            </Box>
           
        </Center>
        <Box pb={5} bg="#040C14">

        <Third 
                heading="Join the vibrant community" 
                bg="radial-gradient(circle at 50% -100%, #131024, #040C14)" 
                cardColor="rgba(255,255,255,.01)"
                paragraph=""
                        />
        </Box>
            
        <Fourth />
    <Center height="auto" bg="#0A0914" fontFamily="heading" pt={10} pb={10}>
        <Box height="100%"  width={["90%","90%","80%","70%"]} d="flex" border="1px solid rgba(255,255,255,.2)" >
            <Box width="80%" height="100%" pl={[10,10,20,10]}>
                
                    <Text as="h2" fontSize={["1.2rem","1.6rem","2.2rem","2.5rem"]} color="brand.900" fontWeight="bold" pt={7}>
                    Stay in the Loop
                    </Text>
                    <Box d="flex" width="100%">
                        <FormControl onKeyPress={enter} width={["90%","90%","80%","60%"]} color="brand.900" pt={3} pb={10} onSubmit={submit}>                 
                            <Input type="email" onChange={changeEmail} value={email} borderRadius="none" fontSize="1.1rem" id='email' placeholder="Your email" height="3.5rem" focusBorderColor="yellow.900"/>
                        </FormControl>
                    <Box pt={5} pl={[5,20,20,60]} ><BsArrowRight onClick={submit} className="move-arrow-right" size="2.2rem" color="#FFC831" cursor="pointer" /></Box>
                    
                    </Box>
                    
                    
            </Box>
          
        </Box>
        
        
    </Center><Center><Text as="h3" fontSize={['1rem','1.1rem','1.1rem','1.1rem']} color="yellow.100" id="success" fontFamily="heading"></Text></Center> 

    </Box>
    
    </>
  )
}

export default Community