import React from 'react'
import {Box , Center , Text , Image , Button , Link} from '@chakra-ui/react'
import logo from '../../../../assets/metacratch.webp'
import meta from '../../../../assets/meta.webp'
import {motion} from 'framer-motion'


function Fourth() {

  const Animate = {
    hidden : {
      x: -100,
      opacity:0
    },
    visible : custom => ({
      x: 0,
      opacity:1,
      transition : {delay : custom * 0.2 , type: 'spring'},
    }),
  }

  const AnimateImage = {
    hidden : {
      y: 100,
      opacity:0
    },
    visible : custom => ({
      y: 0,
      opacity:1,
      transition : {delay : custom * 0.2} , type: 'spring',
    }),
  }

  return (
    <>
    
    <Box bgColor="#0A0914" fontFamily="heading">

    <Center bg="#0A0914" width="100%" height="100%">
            <Box width={["85%","80%","79%","79%"]} d={["grid","grid","flex","flex"]}  mt={20}  height="100%" pb={10} justifyContent="center" alignContent="center" alignItems="center">
            <Box width={["100%","100%","50%","50%"]} height="100%" pl={5} d={['block' , "block" , "none" , "none"]} >
                <motion.div
                          initial="hidden"
                          whileInView="visible"
                          variants={AnimateImage}
                          custom={1}
                          >
                    <Image src={meta} alt="meta" />

                    </motion.div>
                </Box>
                <Box width={["100%","100%","50%","50%"]} >
                <motion.div
                          initial="hidden"
                          whileInView="visible"
                          variants={Animate}
                          custom={1}
                          >
                  <Image src={logo} width="13rem" alt="logo" mt={5}/>
                    <Text fontSize={["1.9rem","2.1rem","2.1rem","2.5rem"]} fontWeight="bold" mt={5} lineHeight="2.6rem" color="brand.900">
                    Create, Explore and Earn in the first-ever MetaSteaming Platform
                    </Text>
                    </motion.div>

                    <motion.div
                          initial="hidden"
                          whileInView="visible"
                          variants={Animate}
                          custom={1.5}
                          >
                    <Text as="p" color="brand.100" fontSize={["1rem","1.1rem","1.2rem","1.2rem"]} mt={5} width={["100%","100%","95%","95%"]}>
                    
Explore lands and sudios owned by users to experience incredible scenes and structures.
Join the community and connect with your favorite streamers and influencers in the Metaverse.
                    </Text>
                    </motion.div>
                    <motion.div whileTap={{scale : 0.9}} initial="hidden"
                          whileInView="visible"
                          variants={Animate}
                          custom={1.6}>
                    <Link href='https://meta.cratch.io/' isExternal>
                    <Button _hover={{
                      bg: '#0088FF'
                    }}  _active={{
                      bg: '#0088FF'
                    }} bg="#0088FF" mt="50px" width="10rem" height="50px" fontWeight="bold" borderRadius="10px" fontSize={["1.1rem","1.2rem","1.3rem","1.3rem"]} color="brand.900">
                        MetaCratch
                    </Button>
                    </Link>
                    </motion.div>
                </Box>
                <Box width={["100%","100%","50%","50%"]}  height="100%" pl={5} d={['none' , "none" , "block" , "block"]} >
                <motion.div
                          initial="hidden"
                          whileInView="visible"
                          variants={AnimateImage}
                          custom={1}
                          >
                    <Image src={meta} alt="meta" />

                    </motion.div>
                </Box>
            </Box>
        </Center>



      
       </Box>
    </>
  )
}

export default Fourth