import React from 'react'
import Navbar from './components/Home/navbar/Navbar';
import Hero from './components/Home/hero/Hero';
import First from './components/Home/sections/First/First';
import Second from './components/Home/sections/Second/Second';
import Third from './components/Home/sections/Third/Third';
import Fourth from './components/Home/sections/Fourth/Fourth';
import Fifth from './components/Home/sections/Fifth/Fifth';
import Footer from './components/Home/footer/Footer';
import Token from './components/Token/Token';
import Team from './components/Team/Team';
import Community from './components/Community/Community';
import Roadmap from './components/Roadmap/Roadmap';
import Terms from './components/Terms/Terms';
import TermsAndConditions from './components/Conditions/TermsAndConditions';
import Cookie from './components/Cookie/Cookie';
import Sixth from './components/Home/sections/Sixth/Sixth';
import { Router } from "@reach/router";
import  OnRouteChange from "reach-router-scroll-top";
import { AnimatePresence } from 'framer-motion';
import Platform from './components/Platform/Platform';
import Metaverse from './components/Metaverse/Metaverse';
import Buy from './components/Buy/Buy';
import Whitepaper from './assets/Whitepaper.pdf';
import {Box} from "@chakra-ui/react"

const Home = () => {
  return (
    <>              
                      <Hero />
                      <First />
                      <Second />
                     
                      <Fourth />
                      <Fifth />
                       <Third 
                      heading="Our Community" 
                      bg="radial-gradient(circle at 50% -100%, #131024, #0E0C1A)" 
                      cardColor="rgba(255,255,255,.01)"
                      paragraph="Join one of the fastest-growing community of Metaverse lovers, crypto enthusiasts, live streamers and content creators. We are intentional about building an international community that is currently supported in 5 languages with more to come."
                      showButton
                      />
                      <Sixth />
    </>
  )
}

const Paper = ()=> {
  return (
    <Box w="100%" h="100vh">
      <object data={Whitepaper} type="application/pdf" width="100%" height="100%" standby="Loading...">
        
      </object>
    </Box>
  );

}


function App() {
  // 2. Wrap ChakraProvider at the root of your app
  return (
    <>
    <AnimatePresence>
      <Navbar />
            <Router>

                <Home path="/" /> 
                <Token path="/token"/> 
                <Platform path="/platform" />
                <Team path="/team" />
                <Community path="/community" />
                <Roadmap path="/roadmap" />
                <Terms path="/terms" />
                <TermsAndConditions path="/terms-and-conditions" />
                <Cookie path="/cookie" />
                <Metaverse path="/metaverse" />
                <Buy path="/buy" />
                <Paper path="/whitepaper"/>
            </Router>
            <OnRouteChange />
      <Footer/>
    </AnimatePresence>
    </>
  )
}

export default App;
