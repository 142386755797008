import React from 'react'
import {Box , Center , Text , Button , Image, Link} from '@chakra-ui/react'
import shape from '../../../../assets/shape.webp'
import {motion} from 'framer-motion'
import {Link as ReachLink } from "@reach/router"


function Fourth() {

  const Animate = {
    hidden : {
      x: -100,
      opacity:0
    },
    visible : custom => ({
      x: 0,
      opacity:1,
      transition : {delay : custom * 0.2 , type: 'spring'},
    }),
  }

  const AnimateImage = {
    hidden : {
      y: 100,
      opacity:0
    },
    visible : custom => ({
      y: 0,
      opacity:1,
      transition : {delay : custom * 0.2 , type: 'spring'},
    }),
  }

  return (
    <>

       <Box bgColor="#0A0914" fontFamily="heading">

<Center bg="#0A0914" width="100%" height="100%">
        <Box width={["85%","80%","79%","79%"]} d={["grid","grid","flex","flex"]}  mt={10}  height="100%" pb={10} justifyContent="center" alignContent="center" alignItems="center">
        <Box width={["100%","100%","50%","50%"]} height="100%" d={['block' , "block" , "none" , "none"]}>
        <motion.div
                          initial="hidden"
                          whileInView="visible"
                          variants={AnimateImage}
                          custom={1}
                          >

                <Image src={shape} alt="road" />

                </motion.div>
            </Box>

            <Box width={["100%","100%","50%","50%"]} height="100%"  d={['none' , "none" , "block" , "block"]} pr={[0,0,20,20]}>
            <motion.div
                          initial="hidden"
                          whileInView="visible"
                          variants={AnimateImage}
                          custom={1}
                          >

                <Image src={shape} alt="road" />


                </motion.div>
            </Box>

            <Box width={["100%","100%","50%","50%"]}  height="100%">
            <motion.div
                          initial="hidden"
                          whileInView="visible"
                          variants={Animate}
                          custom={1}
                          >
                <Text fontSize={["1.9rem","2.1rem","2.1rem","2.5rem"]} fontWeight="bold" mt={5} lineHeight="2.6rem" color="brand.900">
                Our Roadmap
                </Text>
                </motion.div>
                <motion.div
                          initial="hidden"
                          whileInView="visible"
                          variants={Animate}
                          custom={1.5}
                          >
                <Text as="p" color="brand.100" fontSize={["1rem","1.1rem","1.2rem","1.2rem"]} mt={5} width={["100%","100%","90%","90%"]}>
                We are committed to the transparency and accountability of our brands. Check out important milestones on Cratch’s journey from ideation through execution.
                </Text>
                </motion.div>
                <motion.div whileTap={{scale : 0.9}} initial="hidden"
                          whileInView="visible"
                          variants={Animate}
                          custom={1.6}>
                <Link as={ReachLink} to="/roadmap">
                  <Button _active={{
                    bg: '#AB1BEE'
                  }} _hover={{
                    bg: '#AB1BEE'
                  }} bg="#801fef" mt={10} width="12.5rem" height="50px" fontWeight="bold" borderRadius="10px" fontSize={["1.1rem","1.2rem","1.3rem","1.3rem"]} color="brand.900">
                      View Roadmap
                  </Button>
                  </Link>
                  </motion.div>
            </Box>
        </Box>
    </Center>



  
   </Box>

    </>
  )
}

export default Fourth