import React from 'react'
import {Box , Button, Text} from '@chakra-ui/react'
import Section from '../Third/Section'
import {FaTwitter , FaReddit , FaTelegram , FaDiscord} from "react-icons/fa"
import {motion} from 'framer-motion'
import {Link as ReachLink } from "@reach/router"
import { Link } from '@chakra-ui/react'


function Third({heading , bg, cardColor , paragraph , showButton}) {

  const Animate = {
    hidden : {
      y: -100,
      opacity:0
    },
    visible : custom => ({
      y: 0,
      opacity:1,
      transition : {delay : custom * 0.2 , type: 'spring'},
    }),
  }


  return (
    <>
    <Box fontFamily="heading" pt={16} pb={20}   height="105%" bg={`${bg}`} alignItems="center" justifyContent="center" textAlign="center">
    <motion.div
                          initial="hidden"
                          whileInView="visible"
                          variants={Animate}
                          custom={2}
                          viewport={{amount: 0.2 }}
                          >
        <Box textAlign="center">
            <Text color="brand.900" as="h1" pl={10} pr={10} fontSize={["1.5rem","1.7rem","2rem","2rem"]} fontWeight="bold" >{heading}</Text>
            <Box d="flex" width="100%" alignContent="center" alignItems="center" textAlign="center" justifyContent="center" mt={3}>
                <Text fontSize={["1rem","1rem","1.2rem","1.2rem"]} width={["80%","70%","70%" , "55%"]} color="brand.100">
                  {paragraph}
                  </Text>
            </Box>
        
        </Box>
        </motion.div>

        <Box d={["grid","grid","flex","flex"]}  mt={16} width="100%" justifyContent="center" alignContent="center" textAlign="center" alignItems="center">
          <Box pl={[0,0,0,10]} width={["100%","100%","80%","80%"]}  justifyContent="center" gridTemplateColumns="1fr 1fr" alignContent="center" d={["grid","grid","flex","flex"]} textAlign="center" alignItems="center">
            <Section text="" btnText="Follow" bg={`${cardColor}`} link="https://twitter.com/Cratch_CRTC">
            <Link href='https://twitter.com/Cratch_CRTC' isExternal><FaTwitter size="3rem" fill="white" /></Link>

              </Section>
              <Section text="" btnText="Join" bg={`${cardColor}`} link="https://www.reddit.com/r/cratch_community/">
              <Link href='https://www.reddit.com/r/cratch_community/' isExternal><FaReddit size="3rem" fill="white"  /></Link>
              </Section>
              <Section text="" btnText="Join" bg={`${cardColor}`} link="https://t.me/+O87ny4ZhkopiNDQ6">
              <Link href='https://t.me/+O87ny4ZhkopiNDQ6' isExternal><FaTelegram size="3rem" fill="white" /></Link>
              </Section>
              <Section text="" btnText="Join" bg={`${cardColor}`} link="https://discord.gg/KAbgyrm9kd">
              <Link href='https://discord.gg/KAbgyrm9kd' isExternal><FaDiscord size="3rem" fill="white" /></Link>

              </Section>
            </Box>
            
        </Box>
      {showButton && <motion.div whileTap={{scale : 0.9}}><Link as={ReachLink} to="/community"><Button _active={{bg:"#7a8cff"}} bg="#7a8cff" mt={10} width="18.75rem" height="50px" borderRadius="10px" fontSize={["1.1rem","1.1rem","1.2rem","1.2rem"]} color="brand.900" _hover={{
          bg: '#801fef'
        }}>
          Visit the Community Page
        </Button></Link></motion.div>}
        
        
    </Box>
    

    </>
  )
}

export default Third